import { api, definition } from "@uesio/ui"
import { useEffect } from "react"
import get from "lodash/get"

type ComponentDefinition = {
  consent?: {
    componentType: string
    componentId: string
    propertyPath: string
  }
}

const Component: definition.UC<ComponentDefinition> = ({
  context,
  definition,
}) => {
  const PROJECT_ID = api.view.useConfigValue(
    "uesio/saleswings.saleswings_project_id",
  )
  const { consent } = definition
  // @ts-expect-error -- missing types see https://github.com/ues-io/uesio/issues/4401
  const consentState = api.component.useExternalState(
    // @ts-expect-error -- missing types see https://github.com/ues-io/uesio/issues/4401
    api.component.makeComponentId(
      context,
      consent?.componentType,
      consent?.componentId,
    ),
  )
  const hasConsent = consent ? !!get(consentState, consent?.propertyPath) : true

  useEffect(() => {
    const shouldLoad = () => {
      return (
        typeof window !== "undefined" &&
        typeof document !== "undefined" &&
        // @ts-expect-error -- no types for saleswings
        !window.sw &&
        PROJECT_ID &&
        hasConsent
      )
    }

    const loadSW = () => {
      // @ts-expect-error -- no types for saleswings
      // prettier-ignore
      // eslint-disable-next-line prefer-rest-params
      ;(function (g, a, b, c, d, e, i) {g[d] = g[d] || function () {(g[d].q = g[d].q || []).push(arguments);};g[d].l = 1 * new Date(); e = a.createElement(b);i = a.getElementsByTagName(b)[0]; e.async = true;e.src = c;i.parentNode.insertBefore(e, i);sw("init", {pid: PROJECT_ID, debug: !0, transport: "post", clientSideCookie: "true"}, "trackPageviews");sw("trackForms", {mode: "submit"});})(window, document, "script", "https://s.saleswingsapp.com/sw.prod.min.js", "sw");
    }

    if (shouldLoad()) {
      loadSW()
    }
  }, [PROJECT_ID, hasConsent])

  return null
}

export default Component
